// Carousel.js
import React from 'react';
import Slider from 'react-slick';
import CustomCard from './appCard';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../styling/theme';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import iaa from '../assets/images/DateSlide.jpg'
import locator from '../assets/images/LocateSlide.jpg'
import carrental from '../assets/images/carrental.jpg'
import delivery from '../assets/images/restaurantSlide.jpg'
import beauty from '../assets/images/beautySlide.jpg'
import travel from '../assets/images/travelSlide.jpg'


const cardTexts = ["Moving & Delivery App", "Restaurant App", "Ride App", "Shopping App", "Fifth Card"];
const services = [
  { name: 'Dating App', img: iaa },
  { name: 'Beauty APP', img: beauty },
  { name: 'Food Delvery', img: delivery },
  { name: 'Car Rental', img: carrental },
  { name: 'Locator App', img: locator },
  { name: 'Travel App', img: travel },



,
]
const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480, // Adding a smaller breakpoint for very small screens
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <ThemeProvider theme={theme}>
      <Slider {...settings}>
        {services.map((text, index) => (
          <div key={index}>
            <CustomCard text={text.name} bgImageUrl={text.img} />
          </div>
        ))}
      </Slider>
    </ThemeProvider>
  );
};

export default Carousel;
