// CustomCard.js
import React from 'react';
import { Card, CardContent, Typography, Button, Grid, Box, Stack } from '@mui/material';
import { styled } from '@mui/system';
import theme from '../styling/theme';

const GradientCard = styled(Card)(({ bgImageUrl }) => ({
  color: theme.palette.common.white,
  margin: 5,
  alignItems: 'start',
  backgroundImage: `url(${bgImageUrl})`,
  backgroundSize: 'cover',
  backgroundPosition: 'cover',
  width: '98%', // Adjust width as needed
  height: '400px',
  '@media (max-width: 600px)': {
    backgroundPosition: 'center',
  },
  
}));

const CustomCard = ({ text, bgImageUrl }) => {
  return (
    <GradientCard bgImageUrl={bgImageUrl}>
      <CardContent>
        <Grid container spacing={3} justifyContent="space-between" alignItems={'start'}>
          <Grid item xs={12} md={6}>
            <Stack   width={'80%'} // Adjust width as needed
 height={"370px"} justifyContent={'space-between'}>
              <Typography variant="h5" color="white" gutterBottom>
                {text}
              </Typography>
              <Stack width={'90%'} justifyContent={'end'} direction={'row'}>
              <Button variant="outlined" sx={{ width: '200px',color:'white', borderColor:'white' }}>View more</Button>
              </Stack>
            </Stack>

          </Grid>
          <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
            {/* You can add content here if needed */}
          </Grid>
        </Grid>
      </CardContent>
    </GradientCard>
  );
};

export default CustomCard;
