import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AnimatedButtonWithPassingLight from '../components/button';
import theme from '../styling/theme';
import logo from '../assets/images/primeLogo2.png';
import { Stack } from '@mui/material';
import { Fab, useMediaQuery } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import BuildIcon from '@mui/icons-material/Build';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import CodeIcon from '@mui/icons-material/Code';
import DevicesIcon from '@mui/icons-material/Devices';
import CampaignIcon from '@mui/icons-material/Campaign';
import StorageIcon from '@mui/icons-material/Storage';
import SecurityIcon from '@mui/icons-material/Security';
import { MobileFriendly } from '@mui/icons-material';

const callingAnimation = keyframes`
  0% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(-15deg);
  }
`;

const CallingFab = styled(Fab)`
  animation: ${callingAnimation} 2s infinite;
`;

const pages = ['Services', 'About Us'];
const services = [
  { name: 'Mobile App Development', icon: <MobileFriendly /> },
  { name: 'Web App Development', icon: <CodeIcon /> },
];

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElServices, setAnchorElServices] = React.useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenServicesMenu = (event) => {
    setAnchorElServices(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseServicesMenu = () => {
    setAnchorElServices(null);
  };

  const handleButtonClick = () => {
    navigate('/');
  };

  const handleMobileButtonClick = () => {
    navigate('/MobileApp');
  };

  const handleWebButtonClick = () => {
    navigate('/WebApp');
  };

  const handleAboutButtonClick = () => {
    navigate('/about');
  };

  return (
    <AppBar position="fixed" sx={{ background: 'white', color: 'black' }}>
      <Stack>
        <Stack direction={'row'} backgroundColor={'#FF892D'} width={'100%'} justifyContent={'end'}>
          <Typography sx={{ fontWeight: 'bold', margin: 1, marginRight: 8, color: 'white', fontSize: 15 }} variant='h6' textAlign='end'>
            +251937384201 | +251937380601
          </Typography>
        </Stack>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Button onClick={handleButtonClick}> <img src={logo} width="200" height="80" alt="Logo" style={{ margin: 10 }} /></Button>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  page === 'Services' ? (
                    <MenuItem key={page} onClick={handleOpenServicesMenu}>
                      <Typography
                        fontFamily='Source Sans Pro, sans-serif'
                        fontWeight={600}
                        textAlign="center"
                      >
                        {page}
                      </Typography>
                    </MenuItem>
                  ) : (
                    <MenuItem key={page} onClick={handleAboutButtonClick}>
                      <Typography
                        fontFamily='Source Sans Pro, sans-serif'
                        fontWeight={600}
                        textAlign="center"
                      >
                        {page}
                      </Typography>
                    </MenuItem>
                  )
                ))}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                page === 'Services' ? (
                  <Button
                    key={page}
                    onClick={handleOpenServicesMenu}
                    sx={{ my: 2, color: 'black', display: 'block', fontFamily: 'Source Sans Pro, sans-serif', fontWeight: 600 }}
                  >
                    {page}
                  </Button>
                ) : (
                  <Button
                    key={page}
                    onClick={handleAboutButtonClick}
                    sx={{ my: 2, color: 'black', display: 'block', fontFamily: 'Source Sans Pro, sans-serif', fontWeight: 600 }}
                  >
                    {page}
                  </Button>
                )
              ))}
              <Menu
                id="menu-services"
                anchorEl={anchorElServices}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElServices)}
                onClose={handleCloseServicesMenu}
                PaperProps={{
                  sx: {
                    width: '300px',
                    padding: 1,
                  },
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  {services.map((service) => (
                    <MenuItem key={service.name} onClick={handleCloseServicesMenu}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ bgcolor: 'rgba(255, 165, 0, 0.7)', color: 'white', padding: 1, borderRadius: 4 }}>{service.icon}</Box>
                        <Button onClick={service.name === 'Mobile App Development' ?handleMobileButtonClick : service.name === 'Web App Development' ? handleWebButtonClick : null}>
<Typography fontWeight={'bold'} fontSize={'16px'} textAlign="center" fontFamily='sans-serif' sx={{ ml: 1 }}>
{service.name}
</Typography>
</Button>
</Box>
</MenuItem>
))}
</Box>
</Menu>
</Box>
<Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <AnimatedButtonWithPassingLight />
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography textAlign="center" fontFamily='Source Sans Pro, sans-serif'>{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </Container>
  </Stack>
</AppBar>
  );
            }
            export default ResponsiveAppBar;