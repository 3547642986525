import React, {useRef,useEffect,useState} from 'react';
import { Stack, Typography, Box, TextField, Fab,useMediaQuery } from "@mui/material";
import { styled, keyframes } from '@mui/system';
import ResponsiveAppBar from "../layout/appBar";
import CustomCardWide from "../components/appCardWide";
import Carousel from "../components/carousel";
import ChatIcon from '@mui/icons-material/Chat';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram'; // Import Telegram icon
import Footer from '../layout/footer';
import ResponsiveSidebar from '../layout/sidebar';
import theme from '../styling/theme';
import { useNavigate } from 'react-router-dom';
import carRental from '../assets/images/carRental2.png'
import beauty from '../assets/images/Beauty.png'
import delivery from '../assets/images/deliveyMain.png'
import dating from '../assets/images/Dating.png'
import travel from '../assets/images/travel.png'
import locator from '../assets/images/locator3.png'
import carRental2 from '../assets/images/Laptop Mockup-Recovered.png'
import Portfolio from '../assets/images/Portfolio.jpg'
import Construction from '../assets/images/ConstructionCard.png'
import ngo from '../assets/images/NGOCard.png'
import Hospital from '../assets/images/HospitalCard.png'
import HotelBook from '../assets/images/HotelCard.png'
import CarouselTwo from '../components/carouselTwo';






// Styled Fab component with calling animation

const AppList = ['All', 'Agriculture', 'Astrology ', 'Beauty ', 'Car Rental ', 'Car Wash ', 'Dating ', 'E-commerce ', 'Education ', 'Entertainment '];

// Define keyframes for the wave animation
const waveKeyframes = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(33, 150, 243, 0.4), 0 0 0 0 rgba(33, 150, 243, 0.4), 0 0 0 0 rgba(33, 150, 243, 0.4);
  }
  40% {
    box-shadow: 0 0 0 20px rgba(33, 150, 243, 0), 0 0 0 40px rgba(33, 150, 243, 0), 0 0 0 60px rgba(33, 150, 243, 0);
  }
  80% {
    box-shadow: 0 0 0 20px rgba(33, 150, 243, 0), 0 0 0 40px rgba(33, 150, 243, 0.1), 0 0 0 60px rgba(33, 150, 243, 0.2);
  }
  
`;

// Styled Fab component with wave animation
const WaveFab = styled(Fab)`
  animation: ${waveKeyframes} 2.5s infinite;
`;

function HomeScreen() {
  const endOfAppListRef = useRef(null);
  const [reachedEndOfAppList, setReachedEndOfAppList] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (endOfAppListRef.current) {
        const { top } = endOfAppListRef.current.getBoundingClientRect();
        setReachedEndOfAppList(top <= window.innerHeight);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  const handleTg = () => {
    window.open('https://t.me/primesoftwaresupport', '_blank');
  };
  const [selectedApp, setSelectedApp] = useState('');

  const handleAppChange = (event) => {
    setSelectedApp(event.target.value);
  };
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <ResponsiveAppBar />
      <Stack spacing={2} sx={{ paddingTop: '250px', alignItems: 'center', justifyContent: 'space-around' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
          <Typography
            variant='h3'
            sx={{
              color: '#FF892D',
              marginRight: 2,
              fontWeight: 'bold',
              fontSize: {
                xs: '2rem',
                sm: '2.5rem',
                md: '3rem',
                lg: '3.5rem',
                xl: '3rem'
              },
              textAlign: 'center',
            }}
          >
            Transform
          </Typography>
          <Typography
            variant='h3'
            sx={{
              fontWeight: 'bold',
              fontSize: {
                xs: '2rem',
                sm: '2.5rem',
                md: '3rem',
                lg: '3.5rem',
                xl: '3rem'
              },
              textAlign: 'center',
            }}
          >
            The World With Your Idea.
          </Typography>
        </Box>
        <Typography variant='h5'
          sx={{
            fontSize: {
              xs: '2rem',
              sm: '2.5rem',
              md: '3rem',
              lg: '3.5rem',
              xl: '1.4rem'
            },
            textAlign: 'center',
          }}>
          From dream to reality, here are some apps we are proud to be part of.
        </Typography>
      </Stack>
      <Stack margin={5} >
        <Typography fontWeight='bold' variant='h4' textAlign='left' sx={{ paddingBottom: 2 }}>Featured Apps</Typography>
        <Carousel />
        <Typography fontWeight='bold' variant='h4' textAlign='left' sx={{ paddingBottom: 2, paddingTop:2 }}>Featured Websites</Typography>

        <CarouselTwo/>
        <Stack direction={isSmallScreen?'Column':'row'} width={'100%'} spacing={4} paddingTop={5}>
      <ResponsiveSidebar selectedApp={selectedApp} handleAppChange={handleAppChange} />
      <Stack width={'100%'} paddingTop={5} sx={{ overflowY: 'scroll', maxHeight: '1000vh', scrollbarWidth: 'none', msOverflowStyle: 'none', '&::-webkit-scrollbar': { display: 'none' } }}>
        <CustomCardWide text={'Car Rental'} bgImageUrl={ '#5f6a86'} imageUrl={carRental} description={'Car rental is your ultimate car rental solution, offering a seamless and convenient way to rent a car anytime, anywhere. With a user-friendly interface, the app allows you to browse a wide selection of vehicles, check real-time availability, and book a car in just a few taps. Enjoy flexible rental periods, secure payment options, and exclusive deals, all while benefiting from 24/7 customer support. Whether you need an economy car for city driving or an SUV for a family trip, Car Rental has the perfect vehicle to suit your needs, making car rental effortless and reliable. Download Car Rental today and revolutionize your travel experience!'} />
        <CustomCardWide text={'Car Rental'} bgImageUrl={ '#416ea8'} imageUrl={carRental2} description={'Car rental is your ultimate car rental solution, offering a seamless and convenient way to rent a car anytime, anywhere. With a user-friendly interface, the app allows you to browse a wide selection of vehicles, check real-time availability, and book a car in just a few taps. Enjoy flexible rental periods, secure payment options, and exclusive deals, all while benefiting from 24/7 customer support. Whether you need an economy car for city driving or an SUV for a family trip, Car Rental has the perfect vehicle to suit your needs, making car rental effortless and reliable. Download Car Rental today and revolutionize your travel experience!'} />


        
        <CustomCardWide text={'Beauty App'} imageUrl={beauty} bgImageUrl={'#763f3b'}description={'Beauty App is your ultimate beauty assistant, designed to enhance your beauty routine with personalized recommendations, easy appointment bookings, and a seamless shopping experience. Discover tailored beauty tips, book top-rated salon and spa services, and shop for the best products all in one app. Enjoy innovative features like virtual try-on, access expert advice, and connect with a vibrant community of beauty enthusiasts. With exclusive deals, a rewards program, and 24/7 support, Car Rental revolutionizes your approach to beauty, making it more convenient, personalized, and enjoyable. Download Beauty today and elevate your beauty game!'}/>
        <CustomCardWide text={'NGO'} bgImageUrl={ '#5f6a86'} imageUrl={ngo} description={'"NGO Connect" is a dynamic website designed to support non-governmental organizations in their mission to make a positive impact. The platform offers an intuitive interface that provides comprehensive information about various NGOs, their projects, and ways to get involved. Key features include donation portals, volunteer sign-ups, event calendars, and success stories. With robust accessibility options such as text-to-speech and adjustable font sizes, "NGO Connect" ensures inclusivity for all users. The website fosters community engagement, transparency, and collaboration, empowering individuals and organizations to contribute effectively to social causes.'} />

        <CustomCardWide text={'Delivery App'} imageUrl={delivery} bgImageUrl={'#24435a'} description={'Delivery App is your go-to food delivery app, bringing delicious meals from your favorite local restaurants straight to your doorstep. With an intuitive interface, you can easily browse menus, customize your orders, and track deliveries in real-time. Enjoy a wide variety of cuisines, exclusive deals, and fast, reliable service. Whether youre craving comfort food, healthy options, or gourmet dishes, Delivery App ensures a satisfying dining experience every time. Download Delivery  today and savor the convenience of having great food delivered with just a few taps'}/>
        <CustomCardWide text={'Dating App' } imageUrl={dating} bgImageUrl={'#e05d4b'} description={'Dating App is the ultimate food dating app that connects food lovers over their favorite meals. Discover like-minded singles who share your culinary passions, explore diverse restaurants, and plan memorable dining experiences together. With an easy-to-use interface, Dating App allows you to match with potential dates based on shared food interests, chat effortlessly, and arrange your perfect date night. Combining the joy of food with the excitement of dating, Dating App brings people together one delicious meal at a time. Download Dating App today and start your journey to finding love through food!'}/>
        <CustomCardWide text={'Construction'} bgImageUrl={ '#c68b08'} imageUrl={Construction} description={'"BuildSmart" is a cutting-edge construction website designed to facilitate and enhance construction projects of all sizes. The platform offers a user-friendly interface that provides detailed information about construction services, project portfolios, and contractor profiles. Key features include project management tools, real-time progress tracking, and client collaboration portals. With comprehensive resources like industry news, best practices, and regulatory guidelines, "BuildSmart" serves as an invaluable hub for professionals and clients alike. The website ensures accessibility with features such as adjustable font sizes and high-contrast modes, making it a vital tool for efficient and effective construction project management.'} />

        <CustomCardWide text={'Travel App' } imageUrl={travel} bgImageUrl={'#5d9c76'}  description={'TravelApp is your ultimate companion for exploring the world, making travel planning seamless and enjoyable. With TravelApp, discover exciting destinations, book flights, hotels, and activities effortlessly, and get personalized recommendations based on your preferences. Stay organized with itinerary management, real-time updates, and travel tips from seasoned explorers. Whether youre planning a weekend getaway or a global adventure, TravelApp ensures every journey is memorable and stress-free. Download TravelApp today and embark on your next adventure with confidence!'} />
        <CustomCardWide text={'Hostpital Web'} bgImageUrl={ '#103c87'} imageUrl={Hospital} description={'Hospital" is a cutting-edge healthcare website designed to streamline patient care and enhance the overall medical experience. Featuring a user-friendly interface, the platform offers easy access to comprehensive information about hospital services, departments, and medical professionals. It includes essential functionalities such as online appointment scheduling, access to medical records, and communication tools for connecting with healthcare providers. With built-in accessibility features like text-to-speech and adjustable font sizes, "Hospital" ensures that all users, including those with disabilities, can easily navigate and utilize its services.'} />

        <CustomCardWide text={'Locator App' } imageUrl={locator} bgImageUrl={'#637eed'} description={'LocatorApp revolutionizes the way you navigate the world around you, offering an intuitive and comprehensive solution for locating places and services with ease. With LocatorApp, quickly find nearby restaurants, shops, ATMs, gas stations, and more, all within your vicinity. Seamlessly integrated with maps and GPS technology, LocatorApp provides precise directions and real-time updates to ensure you reach your destination efficiently. Whether youre exploring a new city or simply need to find the nearest convenience store, LocatorApp is your go-to tool for navigating the world around you effortlessly. Download LocatorApp now and never get lost again!'} />
      </Stack>
    </Stack>
      </Stack>
      <WaveFab color="primary" aria-label="telegram" onClick={handleTg} sx={{ position: 'fixed', bottom: 56, right: 56 }}>
        <TelegramIcon />
      </WaveFab>
    {isSmallScreen? null: <WaveFab color="#355E3B" aria-label="whatsapp" sx={{ position: 'fixed', bottom: 56, left: 56,backgroundColor: '#25D366',color:'white' }}>
        <WhatsAppIcon />
      </WaveFab> }
      <Footer/>
    </>
  );
}

export default HomeScreen;
