import React from 'react';
import { Container, TextField, Button, Typography, Box, Stack } from '@mui/material';
import ResponsiveAppBar from '../layout/appBar';
import { useMediaQuery } from '@mui/material';
import theme from '../styling/theme';
import Footer from '../layout/footer';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const ContactPage = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); 
  const [phone, setPhone] = React.useState('');

  const handlePhoneChange = (value) => {
    // Remove all non-numeric characters except for '+' sign
    const numericValue = value.replace(/[^0-9+]/g, '');
    setPhone(numericValue);
  };
  return (
    <Box>
      <Stack
        sx={{
          position: 'relative',
          height: '100vh',
          backgroundImage: 'url(https://img.freepik.com/free-photo/top-view-chat-bubbles-with-two-telephone-receivers-cord_23-2148795991.jpg?w=826&t=st=1716534255~exp=1716534855~hmac=293d9da9fc16652343e6d2f8654e30ba04698d122eb72414ba7dd1c9942b1cb1)', // Replace with your image URL
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          overflow: 'hidden',
        }}
      >
        <ResponsiveAppBar />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            bgcolor: 'rgba(255, 165, 0, 0.7)', // Orange overlay with 70% opacity
            zIndex: 1,
          }}
        />
        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ zIndex: 2, width: '100%', height: '100%', justifyContent: 'space-around' }}>
          {isSmallScreen ? null : (
            <Stack width={'600px'}>
              <Typography variant='h2' color={'white'}>Unlock Your Potential Business Potential Today!</Typography>
              <Typography variant='h5' color={'white'}>Fill out the form and our experts will pave their way to contact you within a couple of minutes</Typography>
            </Stack>
          )}
          <Stack maxWidth="sm" sx={{ mt: 5, background: 'white', padding: 5, borderRadius: 10, alignItems: 'center' }}>
            <Typography variant="h4" component="h1" gutterBottom>
              Let's Get Started
            </Typography>
            <Box component="form" noValidate autoComplete="off" alignItems={'center'}>
              <TextField
                fullWidth
                label="Name"
                margin="normal"
                variant="outlined"
                required
              />
               <PhoneInput
        country={'et'}
        value={phone}
        onChange={handlePhoneChange}
        containerStyle={{ marginBottom: '20px' }}
        inputStyle={{ width: '100%', height: '56px' }}
        buttonStyle={{ backgroundColor: '#ffffff', borderRight: 'none' }}
      />
              <TextField
                fullWidth
                label="Email"
                margin="normal"
                variant="outlined"
                type="email"
                required
              />
              
     
              <TextField
                fullWidth
                label="Message"
                margin="normal"
                variant="outlined"
                multiline
                rows={4}
                required
              />
              <Button
                fullWidth
                variant="outlined"
                sx={{ background: '#ffb000', color: 'white', borderRadius: 5, padding:1.2}}
              >
                <Typography sx={{ fontWeight: 'bold' }}>Submit</Typography>
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Stack>
      <Footer />
    </Box>
  );
};

export default ContactPage;
