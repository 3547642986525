import React from 'react';
import Button from '@mui/material/Button';
import { keyframes } from '@emotion/react';
import { styled } from '@mui/system';
import theme from '../styling/theme';
import { useNavigate } from 'react-router-dom';

// Define a keyframe animation with a silver color passing through
const lightPassingAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

// Styled button with animation
const AnimatedButton = styled(Button)(({  }) => ({
  
  borderRadius: 25,
  background: `linear-gradient(to right, ${theme.palette.primary.light}, silver 50%, ${theme.palette.primary.dark})`,
  backgroundSize: '200% auto',
  animation: `${lightPassingAnimation} 2s linear infinite`,
  color: theme.palette.common.white,
  boxShadow: theme.shadows[3],
  '&:hover': {
    animationPlayState: 'paused', // Pause animation on hover
  },
}));

const AnimatedButtonWithPassingLight = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/Contact');
    console.log('object');
  };
  return (
    <AnimatedButton onClick={handleButtonClick} variant="contained">Get In Touch</AnimatedButton>
  );
};

export default AnimatedButtonWithPassingLight;
