// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
      light: '#63a4ff',
      dark: '#004ba0',
    },
    secondary: {
      main: '#ff4081',
      light: '#ff79b0',
      dark: '#c60055',
    },
  },
  shape: {
    borderRadius: 15,
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h5: {
      fontFamily: 'Source Sans Pro, sans-serif',
      fontWeight: 700,
    },
    body1: {
      fontFamily: 'Source Sans Pro, sans-serif',
    },
  },
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
  ],
});

export default theme;
