import React from 'react';
import { Card, CardContent, Stack, Typography, Button, Grid, Box } from '@mui/material';
import { styled } from '@mui/system';
import theme from '../styling/theme';
import { useMediaQuery } from '@mui/material';
import playstore from '../assets/images/pngwing.com.png'
import appstore from '../assets/images/pngwing.com1.png'

const getRandomGradient = () => {
  const gradients = [
    'linear-gradient(45deg,#f6d5be, #f7f1cc)',
    'linear-gradient(45deg,#e5cdd3, #e6e4e0)',
    'linear-gradient(45deg,#f6d5be, #f7f1cc)',
    'linear-gradient(45deg,#d4e9d9, #d4fbe2)',
  ];
  return gradients[Math.floor(Math.random() * gradients.length)];
};

const GradientCard = styled(Card)(({ bgImageUrl }) => ({
  background: bgImageUrl ? bgImageUrl : getRandomGradient(),
  backgroundSize: 'cover',
  color: theme.palette.common.white,
  margin: 5,
  width: '100%',
  borderRadius: 15,
  display: 'flex',
  flexDirection: 'column',
}));

const CustomCardWide = ({ text, bgImageUrl, imageUrl, description }) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <GradientCard bgImageUrl={bgImageUrl}>
      <CardContent sx={{ }}>
        <Grid container spacing={isSmallScreen ? 2 : 1} direction={isSmallScreen ? 'column' : 'row'} justifyContent={'space-between'}>
          <Grid item xs={12} md={5}>
            <Stack spacing={4} alignItems={isSmallScreen ? 'center' : 'start'} sx={{ justifyContent: 'space-evenly' }}>
              <Typography variant={isSmallScreen ? 'h5' : 'h3'} color="white" textAlign={isSmallScreen ? 'center' : 'left'}>
                {text}
              </Typography>
              <Box paddingTop={1}>
                <Typography  fontSize={isSmallScreen?14:20} color="white" textAlign={isSmallScreen ? 'center' : 'left'}>
                  {description}
                </Typography>
              </Box>
              <Button
                variant="outlined"
                sx={{ width: '200px', background: '#ffb000', color: 'white', borderRadius: 5 }}
              >
                <Typography sx={{ fontWeight: 'bold' }}>View More</Typography>
              </Button>
              <Stack spacing={6} direction="row" justifyContent={isSmallScreen ? 'center' : 'start'}>
                <img
                  src={playstore}
                  alt="Play Store"
                  height={50}
                  width={50}
                />
                <img
                  src={appstore}
                  alt="Google"
                  height={50}
                  width={50}
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={7} display="flex" justicdfyContent={isSmallScreen ? 'center' : 'flex-end'}>
            <img src={imageUrl} alt="Logo" style={{ paddingTop: '2px', maxWidth: isSmallScreen ? '100%' : '800px', width: '100%' ,height:"85%"}} />
          </Grid>
        </Grid>
      </CardContent>
    </GradientCard>
  );
};

export default CustomCardWide;
