import logo from './logo.svg';
import './App.css';
import HomeScreen from './pages/home';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import ContactPage from './pages/getInTouch';
import MobileApp from './pages/MobileApp';
import WebApp from './pages/WebApp';
import AboutPage from './pages/about';

function App() {
  return (
    <Router>
    <Routes>
      <Route exact path="/Contact" element={<ContactPage />} />
      <Route exact path="/" element={<HomeScreen />} />
      <Route exact path="/MobileApp" element={<MobileApp />} />
      <Route exact path="/about" element={<AboutPage />} />

      <Route exact path="/WebApp" element={<WebApp />} />

      {/* Add more routes as needed */}
    </Routes>
  </Router>
  );
}

export default App;
