import React from 'react';
import { Box, Typography, useMediaQuery,Stack } from '@mui/material';
import ResponsiveAppBar from '../layout/appBar';
import Footer from '../layout/footer';
import theme from '../styling/theme';

function AboutPage() {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Box>
      <Stack
        sx={{
          position: 'relative',
          backgroundImage: 'url(https://img.freepik.com/free-photo/top-view-chat-bubbles-with-two-telephone-receivers-cord_23-2148795991.jpg?w=826&t=st=1716534255~exp=1716534855~hmac=293d9da9fc16652343e6d2f8654e30ba04698d122eb72414ba7dd1c9942b1cb1)', // Replace with your image URL
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          overflow: 'hidden',
        }}
      >
      <ResponsiveAppBar />
      <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            bgcolor: 'rgba(255, 165, 0, 0.7)', // Orange overlay with 70% opacity
            zIndex: 1,
          }}
        />
      <Box sx={{ padding: 20, zIndex: 2  }}>
        <Typography  variant={isSmallScreen ? 'h4' : 'h2'} textAlign="center" fontWeight="bold" color="white" mb={2}>
          About Us
        </Typography>
        <Typography variant={isSmallScreen ? 'body1' : 'h5'} color="white"  textAlign="center" mb={4}>
        The "About Us" section for Prime Software encapsulates the essence of the company's identity and mission. Prime Software, headquartered in Addis Ababa, Ethiopia, stands as a pioneering force in the realm of software solutions. With a relentless pursuit of innovation and excellence, Prime Software endeavors to revolutionize the digital landscape, catering to diverse client needs with cutting-edge technological interventions.

At Prime Software, our ethos revolves around empowering businesses and individuals through bespoke software solutions tailored to their unique requirements. Through a potent blend of creativity, expertise, and unwavering commitment, we strive to address the evolving challenges of the digital age, driving sustainable growth and fostering lasting relationships with our clientele.        </Typography>
        <Typography variant={isSmallScreen ? 'body1' : 'h5'} color="white"  textAlign="center" mb={4}>
        Our journey is defined by a passion for harnessing the transformative power of technology to propel businesses towards success. From conceptualization to execution, we embark on a collaborative journey with our clients, ensuring that every software solution we deliver aligns seamlessly with their objectives and aspirations.

With a team of seasoned professionals and a track record of delivering exceptional results, Prime Software continues to set new benchmarks in the software development landscape. Our dedication to quality, innovation, and customer satisfaction remains unwavering, making us the trusted partner of choice for organizations seeking to thrive in an increasingly digital world.        </Typography>
        <Typography variant={isSmallScreen ? 'body1' : 'h5'} color="white"  textAlign="center">

        The "About Us" section for Prime Software encapsulates the essence of the company's identity and mission. Prime Software, headquartered in Addis Ababa, Ethiopia, stands as a pioneering force in the realm of software solutions. With a relentless pursuit of innovation and excellence, Prime Software endeavors to revolutionize the digital landscape, catering to diverse client needs with cutting-edge technological interventions.

At Prime Software, our ethos revolves around empowering businesses and individuals through bespoke software solutions tailored to their unique requirements. Through a potent blend of creativity, expertise, and unwavering commitment, we strive to address the evolving challenges of the digital age, driving sustainable growth and fostering lasting relationships with our clientele.

Our journey is defined by a passion for harnessing the transformative power of technology to propel businesses towards success. From conceptualization to execution, we embark on a collaborative journey with our clients, ensuring that every software solution we deliver aligns seamlessly with their objectives and aspirations.

With a team of seasoned professionals and a track record of delivering exceptional results, Prime Software continues to set new benchmarks in the software development landscape. Our dedication to quality, innovation, and customer satisfaction remains unwavering, making us the trusted partner of choice for organizations seeking to thrive in an increasingly digital world.

In summary, Prime Software is not just a software development company; it's a catalyst for digital transformation, shaping the future of businesses and communities through groundbreaking software solutions tailored to inspire growth and drive success.        </Typography>
      </Box>
      <Footer sx={{ zIndex: 5}}/>
      </Stack>
      </Box>
    </>
  );
}

export default AboutPage;
