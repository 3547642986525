import React from 'react';
import Button from '@mui/material/Button';
import { keyframes, styled } from '@mui/system';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Typography, Box } from '@mui/material';
import theme from '../styling/theme';

const moveCircle = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(8px);
  }
  100% {
    transform: translateX(0);
  }
`;

const lightPassing = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const AnimatedButton = styled(Button)(({  }) => ({
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: `linear-gradient(90deg, ${theme.palette.primary.main}, silver, ${theme.palette.primary.main})`,
  backgroundSize: '200% 200%',
  animation: `${lightPassing} 7s infinite`,
  padding: 10,
  width: 250,
  borderRadius: 25,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    '& .arrow-container': {
      animation: 'none',
      transform: 'translateX(-8px)',
    },
  },
}));

const ArrowContainer = styled(Box)(({  }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'black',
  width: 36,
  height: 36,
  borderRadius: '50%',
  backgroundColor: theme.palette.common.white,
  marginLeft: theme.spacing(1),
  animation: `${moveCircle} 1.5s infinite linear`,
}));

const ButtonWithArrow = () => {
  return (
    <AnimatedButton variant="contained">
      <Typography>Build Your App Now</Typography>
      <ArrowContainer className="arrow-container">
        <ArrowForwardIcon />
      </ArrowContainer>
    </AnimatedButton>
  );
};

export default ButtonWithArrow;
