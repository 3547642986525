import React from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { styled } from '@mui/system';
import logo from '../assets/images/primeLogo2-removebg-preview.png';

const FooterContainer = styled('footer')({
  backgroundColor: '#333',
  color: '#fff',
  padding: '40px 0',
});

const Footer = () => {
  return (
    <FooterContainer>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
            </Typography>
            <img src={logo} height={'100px'} width={'250px'}/>
            <Typography variant="body1">
             
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Box>
              <Link href="#" color="inherit" variant="body1" sx={{ display: 'block', mb: 1 }}>
                Home
              </Link>
              <Link href="#" color="inherit" variant="body1" sx={{ display: 'block', mb: 1 }}>
                About Us
              </Link>
              <Link href="#" color="inherit" variant="body1" sx={{ display: 'block', mb: 1 }}>
                Services
              </Link>
              <Link href="#" color="inherit" variant="body1" sx={{ display: 'block', mb: 1 }}>
                Contact Us
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Contact Information
            </Typography>
            <Typography variant="body1">
             Haile GebreSellasie Ave, Addis Ababa, Ethiopia
            </Typography>
            <Typography variant="body1">
              Phone: +251 456 7890
            </Typography>
            <Typography variant="body1">
              Email: primesoftware@gmail.com
            </Typography>
          </Grid>
        </Grid>
        <Box mt={4} textAlign="center">
          <Typography variant="body2">
            © {new Date().getFullYear()} Prime Software. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
