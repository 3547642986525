import React from 'react';
import { Stack, Typography, Box, TextField, Fab,useMediaQuery } from "@mui/material";
import ResponsiveAppBar from '../layout/appBar';
import Footer from '../layout/footer';
import img from '../assets/images/5809359-01.png';
import img2 from '../assets/images/png.png';
import ButtonWithArrow from '../components/BottonArrow';
import { CheckBox } from '@mui/icons-material';
import theme from '../styling/theme';

const WebApp = () => {
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <ResponsiveAppBar />
      <Stack
        sx={{
          position: 'relative',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          overflow: 'hidden',
          padding:isSmallScreen?4: 25,
          paddingTop:isSmallScreen?10:null
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
          }}
        />
        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ zIndex: 2, width: '100%', height: '100%', justifyContent: 'space-around' }}>
          <Stack>
            <Typography variant={isSmallScreen?'h4':'h2' } color='black'>Company Website</Typography>
            <Typography variant={isSmallScreen?'h4':'h2' }color='orange'> Development </Typography>
            <Box width={isSmallScreen?400:700} paddingTop={4} paddingBottom={4}>
              <Typography fontSize={18}>At Prime Software, we believe in transforming ideas into cutting-edge mobile applications. As a leading software development company, we specialize in creating bespoke mobile solutions tailored to meet the unique needs of our clients. Our team of dedicated experts is passionate about harnessing the power of technology to deliver innovative and user-friendly mobile applications across various platforms.</Typography>
            </Box>
            <ButtonWithArrow />
          </Stack>
          <img src={img} width={800} height={600} />
        </Stack>
      </Stack>
      <Typography fontWeight={'bold'} fontSize={69} sx={{ textAlign: 'center' }}>Elevate User Experience</Typography>
      <Typography color={'#00649e'} fontWeight={'bold'} fontSize={69} sx={{ textAlign: 'center' }}>Website Development Services</Typography>
      <Typography fontSize={20} sx={{ textAlign: 'center' }}>Website development services for the modern era that exceeds market norms and industry standards.</Typography>
      <Stack direction={'row'} justifyContent={'space-evenly'} spacing={0} padding={0}>
        <Stack paddingRight={0} paddingTop={15} spacing={3}>
          <Box color={'#00649e'} sx={{ boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.6)', padding: 3, borderRadius: 7 }}>
            <Typography textAlign={'left'} variant={isSmallScreen?'h6':'h5' }><CheckBox /> Cutting-Edge digital Solutions</Typography>
          </Box>
          <Box color={'#00649e'} sx={{ boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.6)', padding: 3, borderRadius: 7 }}>
            <Typography textAlign={'left'} variant={isSmallScreen?'h6':'h5' }><CheckBox /> C-Suite Consultation</Typography>
          </Box>
          <Box color={'#00649e'} sx={{ boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.6)', padding: 3, borderRadius: 7 }}>
            <Typography textAlign={'left'} variant={isSmallScreen?'h6':'h5' }><CheckBox /> Next-Gen Designs For User Delight</Typography>
          </Box>
          <Box color={'#00649e'} sx={{ boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.6)', padding: 3, borderRadius: 7 }}>
            <Typography textAlign={'left'} variant={isSmallScreen?'h6':'h5' }><CheckBox /> Dedicated Mobile App Developers</Typography>
          </Box>
          <Box color={'#00649e'} sx={{ boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.6)', padding: 3, borderRadius: 7 }}>
            <Typography textAlign={'left'} variant={isSmallScreen?'h6':'h5' }><CheckBox /> Quality Assurance & Testing</Typography>
          </Box>
          <Box color={'#00649e'} sx={{ boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.6)', padding: 3, borderRadius: 7 }}>
            <Typography textAlign={'left'} variant={isSmallScreen?'h6':'h5' }><CheckBox /> Secured NDA Agreements</Typography>
          </Box>
        </Stack>
        {isSmallScreen?null:<img src={img2} width={750} height={750} />}
      </Stack>
      <Footer />
    </Box>
  );
};

export default WebApp;
