import React, { useState } from 'react';
import { Box, TextField, Typography, Stack, Drawer, IconButton, useMediaQuery, MenuItem, Select, FormControl } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import theme from '../styling/theme';

const AppList = ['All', 'Agriculture', 'Astrology ', 'Beauty ', 'Car Rental ', 'Car Wash ', 'Dating ', 'E-commerce ', 'Education ', 'Entertainment '];

const ResponsiveSidebar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedApp, setSelectedApp] = useState('');
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleAppChange = (event) => {
    setSelectedApp(event.target.value);
  };

  return (
    <>
      {isSmallScreen ? (
        <Box>
          <FormControl fullWidth>
            <Select
              value={selectedApp}
              onChange={handleAppChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="">
                <em>Search...</em>
              </MenuItem>
              {AppList.map((text, index) => (
                <MenuItem key={index} value={text}>{text}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      ) : (
        <Box marginTop={5} paddingTop={18} sx={{ backgroundColor: '#e8f5ff', borderRadius: 5, position: 'sticky', top: '100px', height: '80vh', overflowY: 'auto', padding: 2 }}>
          <TextField
            variant="outlined"
            placeholder="Search..."
            size="small"
            sx={{ mr: 1, borderColor: 'white' }}
          />
          {AppList.map((text, index) => (
            <Stack key={index} margin={2} alignItems={'start'} height={'50px'}>
              <Typography variant="h5" color={'#717171'}>{text}</Typography>
            </Stack>
          ))}
        </Box>
      )}
    </>
  );
};

export default ResponsiveSidebar;
